/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import url('https://fonts.cdnfonts.com/css/lucida-sans');
html, body { height: 100%; }
body { margin: 0; font-family: 'Lucida Sans', sans-serif;}

@font-face{
  font-family: 'Lucida Sans', sans-serif;
  font-family: 'Lucida Sans Typewriter', sans-serif;
  font-family: 'Lucida Sans Unicode', sans-serif;
  font-style:normal;
  font-weight:400;
  src:local('Lucida Sans'),
  url(https://fonts.cdnfonts.com/s/16217/LSANS.woff) format('woff');
}
$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn,
 ),
 typography: mat.define-typography-config(),
 density: 0,
));
menu{
  padding: 0px !important;
  margin: 0px;
}
.marginTop20{
  margin-top:20px;
}
.mat-mdc-text-field-wrapper{
  border: 0px solid #5A7AFA !important;
  border-radius: 8px !important;
}
.lucidaFont, a, html, body, h1, span, ul li{
  font-family: 'Lucida Sans', sans-serif !important;
  font-size: 16px !important;
}
#myframe{
  border: none;
  border-left: 1px solid #c5c7d5;
}
.marginTop10{
  margin-top:10px;
}
.loginForm .mat-mdc-form-field-subscript-wrapper{
  display: none !important;
}
.loginForm .mat-mdc-form-field-infix{
  padding-top: 15px !important;
}
.quickContent a, .left-pane a{
  display: block;
  font-size: 14px !important;
  padding: 10px 0px;
}
.left-pane br{
display: none;
}
.header-btn .mdc-button__label{
    font-size: 14px !important;
  }